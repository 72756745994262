import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CommonHeading from './CommonHeading'
import img1 from '../../images/2mobiles-removebg-preview.png'
import bubble from '../../images/bubble2.webp'
import { GoDotFill } from "react-icons/go";

const BulletData = ({contentData}) => {
  return (
    <div className='right-image-component-section section-content'>
            <Container>
                <Row>
                    <Col lg={5} className='order-2 order-lg-0'>
                        <div className='right-info'>
                            <CommonHeading text={contentData !== undefined && contentData.text} para={contentData !== undefined && contentData.para} head={contentData !== undefined && contentData.heading} headSpan={contentData !== undefined && contentData.headSpan} />
                            <p class="text mb-3">We designed Suti to simplify the process of tracking your health, understanding your body's unique rhythms, and staying in tune with your well-being.</p>
                            <p class="text mb-3">Pair the Suti mobile app with your health tracking data for:</p>
                            <ul className='bulletspoint'>
                                {contentData !== undefined && contentData.content.map((ele, i)=>{
                                    return(
                                    <li class="d-flex align-items-center py-2" key={i}>
                                    <GoDotFill className='me-2 lh-1' /><h6 class="fw-bold op-4">{ele}</h6>
                                    </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={6} className='order-0 order-lg-2'>
                        <div className='mb-30 mb-lg-0'>
                            <img src={img1} alt="left-img"></img>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img src={bubble} alt="bubble" className='right-bubble'></img>
        </div>
  )
}

export default BulletData
