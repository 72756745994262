import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bubble from '../../images/bubble2.webp'
import lines from '../../images/lines.webp'
import lady from '../../images/yoga-pose.jpg'
import lady2 from '../../images/yoga-pose-removebg-preview.png'
import CommonHeading from './CommonHeading'
import { FaTag, FaSync, FaTextWidth } from "react-icons/fa";

const Yoga = () => {
    const contentData = {
        "text": "",
        "para": "",
        "heading": "Suti Wellness: Embrace the Journey with",
        "headSpan": "Prenatal Yoga",
        "content":[]
    }
    
  return (
    <div className='yoga-section left-image-component-section section-content'>
        <Container>
            <Row>
                <Col lg={6} className='d-flex align-items-center'>
                    <div className='mb-30 mb-lg-0'>
                        <img src={lady2} alt="left-img"></img>
                    </div>
                </Col>
                <Col lg={6}  className='d-flex align-items-center'>
                    <div className='left-info'>
                        <CommonHeading text={contentData !== undefined && contentData.text} para={contentData !== undefined && contentData.para} head={contentData !== undefined && contentData.heading} headSpan={contentData !== undefined && contentData.headSpan} />
                        <p>Prenatal yoga is a transformative practice that combines meditation, breathing techniques, and postures to nurture both body and mind during pregnancy. It is a holistic approach designed to soothe nerves, alleviate discomfort, and prepare the body for the beautiful journey of motherhood. This age-old practice, when tailored for pregnant women, offers incredible benefits for both the mother and her baby.</p>
                        <ul className='mt-4'>
                            <li className='d-flex align-items-center mb-3'>
                                <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTag /></small>
                                <h6 ><b>Relieves Pregnancy Discomforts:</b> Helps manage back pain, swelling, and fatigue</h6>
                            </li>
                            <li className='d-flex align-items-center mb-3'>
                                <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaSync /></small>
                                <h6><b>Enhances Flexibility and Strength:</b> Prepares your body for labor and delivery</h6>
                            </li>
                            <li className='d-flex align-items-center mb-3'>
                                <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTextWidth /></small>
                                <h6><b>Reduces Stress and Anxiety:</b> Promotes mental calmness through breathing exercises.</h6>
                            </li>
                            <li className='d-flex align-items-center mb-3'>
                                <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTextWidth /></small>
                                <h6><b>Improves Circulation:</b> Encourages better oxygen flow for both mother and baby.</h6>
                            </li>
                            <li className='d-flex align-items-center mb-3'>
                                <small className='rounded-circle me-3 d-inline-flex align-items-center justify-content-center'><FaTextWidth /></small>
                                <h6><b>Prepares for Childbirth:</b> Builds stamina and resilience for labor.</h6>
                            </li>
                        </ul>
                        <p>Suti offers expert-guided, personalized yoga plans to make your pregnancy smoother and more fulfilling. With the right guidance, prenatal yoga becomes not just a practice, but a source of empowerment and joy as you embark on the journey into motherhood.</p>
                    </div>
                </Col>
            </Row>
        </Container>
        <img src={lines} alt="lines" className='left-lines'></img>
        <img src={bubble} alt="bubble" className='left-bubble'></img>
    </div>
  )
}

export default Yoga
