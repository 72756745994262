import React from 'react'
import { FaChevronUp } from "react-icons/fa";

const ScrollTop = () => {
    return (
        <a href="#" className="to_top bg-gray rounded-circle icon-40 d-inline-flex align-items-center justify-content-center show">
            <FaChevronUp className='text-dark' />
        </a>
    )
}

export default ScrollTop
