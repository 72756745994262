import React from 'react'
import { Container } from 'react-bootstrap'
import SmallContent from './SmallContent'
import f1 from '../../images/fe1.png'
import f2 from '../../images/fe2.png'
import f3 from '../../images/fe3.png'
import f6 from '../../images/fe6.png'
// import f4 from '../../images/fe4.webp'
import f5 from '../../images/fe5.png'
import circle from '../../images/rotate_circle.webp'

const Features = () => {
  const text = "Notero - Easy Notes App";
  const featureImg = [
    {
      "id":1,
      "img": f1,
      "alt": "feature1",
      "text": "Daily LIVE Yoga" 
    },
    {
      "id":2,
      "img": f6,
      "alt": "feature1",
      "text": "Calculator Tools" 
    },
    {
      "id":3,
      "img": f3,
      "alt": "feature3",
      "text": "Talk to Baby Activities" 
    },
    {
      "id":4,
      "img": f5,
      "alt": "feature5",
      "text": "Diet Guidance"  
    }
  ]
  return (
    <div className='feature-section pt-70 pb-70'>
      <Container>
        <div className='feature-heading text-center'>
            {/* <SmallContent text={text} /> */}
            <h2 className="mb-70">Awesome <span> Features </span> </h2>
        </div>
        <div className='feature-content'>
          {featureImg.map((ele, i)=>{
            return(
              <div className='feature-card' key={i}>
                <div className='feature-icon'>
                  <img src={ele.img} alt={ele.alt}></img>
                </div>
                <h6>{ele.text}</h6>
              </div>
            )
          })}
        </div>
        <img src={circle} alt="circle" className='rotate-circle'></img>
      </Container>
    </div>
  )
}

export default Features
