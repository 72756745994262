import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import appStore from '../../images/appstore.webp'
import playStore from '../../images/googleplay.webp'
// import img1 from '../../images/slider1.webp'
import img1 from '../../images/app3-1.png'
import img2 from '../../images/app3-2.png'
import bubble from '../../images/banner_bubble.webp'
import wave from '../../images/banner_wave.webp'
import SmallContent from './SmallContent'
import { useMediaQuery } from 'react-responsive'

const Banner = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const text = "Notero - Easy Notes App";
    return (
        <>
            <div className='banner-section'>
                <div className='banner-content'>
                    <Container>
                        <Row className='gx-0'>
                            <Col lg={6}>
                                <div className='banner-info'>
                                    {/* <SmallContent text={text} /> */}
                                    <h1 class="mb-30">All Women’s Health, <span>One Suti!</span></h1>
                                    <p class="text">Suti is the science-backed, data-driven women's health app{!isMobile && <br/>}for menstrual and reproductive health.</p>
                                    <div className='d-flex align-items-center mt-50' >
                                        <NavLink className=""><img src={appStore} alt="app-store" className='app-store'></img></NavLink>
                                        <NavLink className=""><img src={playStore} alt="play-store"></img></NavLink>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='banner-img'>
                                    <img src={img1} alt="banner-img" className='img-fluid first-img'></img>
                                    <img src={img2} alt="banner-img" className='img-fluid sec-img'></img>
                                </div>
                            </Col>
                        </Row>
                        <img src={bubble} alt="bubble" className='banner-bubble'></img>
                    </Container>
                    <img src={wave} alt="wave" className='banner-wave'></img>
                </div>
            </div>
        </>
    )
}

export default Banner
