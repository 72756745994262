import React from 'react'
import SmallContent from './SmallContent'

const CommonHeading = (props) => {
    return (
        <>
            <div>
                {props.text != "" && 
                <SmallContent text={props.text} />
                }
                <h2 className="mb-30">{props.head} <span> {props.headSpan} </span> </h2>
            </div>
            <p className="text mb-4">{props.para}</p>
        </>
    )
}

export default CommonHeading
