import React, {useEffect, useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CommonHeading from './CommonHeading'
import img1 from '../../images/inside1.webp'
import img2 from '../../images/inside2.webp'
import img3 from '../../images/app1.png'
import img4 from '../../images/tab5.png'
import WOW from 'wowjs';
import 'animate.css';


const Inside = () => {
  const [activeTab, setActiveTab] = useState('feature_tab1');
  const contentData = {
    "text": "",
    "para": "",
    "heading": "Inside The",
    "headSpan": "App",
    "content": []
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className='inside-section primary-bg' id="calculators">
      {/* <div className='screenshots-slider'>
        <Container>
            <div className='text-center'>
            <CommonHeading text={contentData !== undefined && contentData.text} para={contentData !== undefined && contentData.para} head={contentData !== undefined && contentData.heading} headSpan={contentData !== undefined && contentData.headSpan} />
            </div>
            <Row>
                <Col lg={4}>
                    <img src={img1} className='img-fluid'></img>
                </Col>
                <Col lg={4}>
                    <img src={img2} className='img-fluid'></img>
                </Col>
                <Col lg={4}>
                    <img src={img1} className='img-fluid'></img>
                </Col>
            </Row>
        </Container>
      </div> */}

      <div className="app-tabs-two">
        <div className="container">
          <div id="pix-tabs" className="tabs-four">
            <div className="row d-flex align-items-center">
              <div className="col-md-4">
                <div className="app-tab-content-nav">
                  <ul id="pix-tabs-nav" className="wow fadeInUp" data-wow-delay="0.7s">
                    <li className={activeTab === 'feature_tab1' ? 'active' : ''} onClick={() => handleTabClick('feature_tab1')}>
                   
                        <i className="ei ei-icon_house"></i> Ovulation Calculator
                 
                    </li>
                    <li className={activeTab === 'feature_tab2' ? 'active' : ''} onClick={() => handleTabClick('feature_tab2')}>
                      
                        <i className="ei ei-icon_lock-open_alt"></i> Period Tracker
                      
                    </li>
                    <li className={activeTab === 'feature_tab3' ? 'active' : ''} onClick={() => handleTabClick('feature_tab3')}>
                      
                        <i className="ei ei-icon_lightbulb_alt"></i> BMI Calculator
                      
                    </li>
                    <li className={activeTab === 'feature_tab4' ? 'active' : ''} onClick={() => handleTabClick('feature_tab4')}>
                      <i className="ei ei-icon_creditcard"></i> Pregnancy Due Date Calculator
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8">
                <div className="tabs-content-wrapper">
                  <div id="pix-tabs-content" className="app-tabs-content wow fadeIn">
                    <div id="feature_tab1" className={`content ${activeTab === 'feature_tab1' ? 'd-block' : 'd-none'}`}>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="tab-image text-center">
                            <img src={img3} alt="sasspik tab" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="app-tabs-content-inner">
                            {/* <h4 className="tab-sub-title">Ovulation Calculator</h4> */}
                            <h2 className="tab-title mb-3">Ovulation Calculator</h2>
                            <div className="tab-description">
                              <p className='mb-2'>
                              Receive daily conception tips from our experts and discover how to interpret your body’s fertility signals with the Suti app's advanced ovulation tracker. </p>
                              <p>Maximize your chances of achieving a positive pregnancy test each cycle with personalized insights and guidance tailored to your journey.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="feature_tab2" className={`content ${activeTab === 'feature_tab2' ? 'd-block' : 'd-none'}`}>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="tab-image text-center">
                            <img src={img4} alt="sasspik tab" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="app-tabs-content-inner">
                            <h3 className="tab-title mb-3">Period Tracker</h3>
                            <div className="tab-description">
                              <p className='mb-2'>
                              Discover your unique cycle patterns with the Suti app's period and cycle tracker. Track your symptoms, identify trends, and stay prepared by knowing when your next period is likely to start.
                              </p>
                              <p> Stay in sync with your body effortlessly!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="feature_tab3" className={`content ${activeTab === 'feature_tab3' ? 'd-block' : 'd-none'}`}>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="tab-image text-center">
                            <img src={img3} alt="sasspik tab" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="app-tabs-content-inner">
                            <h3 className="tab-title mb-3">BMI Calculator</h3>
                            <div className="tab-description">
                              <p className='mb-2'>
                              Being underweight or overweight can have adverse effects on your body, and the BMI calculator (kg with age) is a valuable tool for determining if further medical tests might be necessary.
                              </p>
                              <p>With the Suti app, you can conveniently monitor and track your BMI to stay on top of your health.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="feature_tab4" className={`content ${activeTab === 'feature_tab4' ? 'd-block' : 'd-none'}`}>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="tab-image text-center">
                            <img src={img4} alt="sasspik tab" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="app-tabs-content-inner">
                            <h3 className="tab-title">Pregnancy Due Date Calculator</h3>
                            <div className="tab-description">
                              <p className='mb-2'>
                              When is your baby due? With the Suti App, you can easily calculate your due date based on the date of your last menstrual period, conception date, IVF three-day or five-day transfer date, or your last ultrasound. 
                              </p>
                              <p>Track your pregnancy journey effortlessly and stay informed every step of the way with Suti!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inside
