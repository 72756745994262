import React from 'react';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import logo from '../../images/logo.png';
import { HiBars3 } from "react-icons/hi2";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <Navbar expand="lg" className="navbar-light nav-header header">
      <Container fluid className="d-flex justify-content-between align-items-center">
        
        {/* Logo on the left */}
        <Navbar.Brand href="#" className="me-auto">
          <img src={logo} alt="Logo" className="sec-logo" />
        </Navbar.Brand>

        {/* Centered Menu */}
        <Navbar.Toggle aria-controls="navbarTogglerDemo02" className="ms-auto">
          <HiBars3 size={30} />
        </Navbar.Toggle>

        <Navbar.Collapse id="navbarTogglerDemo02" className="justify-content-center">
          <Nav className="mx-auto">
            <NavLink 
              to="/" 
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              end
            >
              Product
            </NavLink>
            <Link className="nav-link" to="calculators" smooth={true} duration={1000}>
            Calculators
            </Link>
            <NavLink 
              to="/contact" 
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>

        {/* Email and Contact on the right */}
        <div className="d-none d-lg-flex align-items-center">
          <div className="touch-div text-end">
            <Button className="rounded-pill">Try Suti Today</Button>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
