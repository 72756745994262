import React from 'react'
import wave from '../../images/small_wave.webp'
import LeftImageComponent from './LeftImageComponent'
import RightImageComponent from './RightImageComponent'
import Integration from './Integration'
import Accordian from './Accordian'
import BulletData from './BulletData'
import Yoga from './Yoga'
import Diet from './Diet'

const ImageTextComponent = () => {
  const data1 = {
    "text": "",
    "para": "",
    "heading": "What can you achieve with the",
    "headSpan": "Suti app?",
    "content": ["Track your pregnancy journey week by week", "Gain insight into your fertility health", "Monitor your cycle and symptoms easily"]
  }
  const data2 = {
    "text": "",
    "para": "Once you decide to focus on your health, results can’t come fast enough! That’s why Suti is dedicated to empowering women like you to better understand their bodies and natural health cycles, helping you achieve your wellness goals sooner.",
    "heading": "Have a Baby With",
    "headSpan": "Suti",
    "content": [
      "Accurate period & ovulation predictions",
      "Personalized cycle reports", 
      "Automatic ovulation test readings",
      "Simple period and cervical mucus tracking",
      "Optimal intercourse timing",
      "Tracking your hormones and fertile window",
      "Smart ovulation and BBT charting"
    ]
  }
  const data3 = {
    "text": "",
    "para": "Install Suti to access expert resources supporting every step of your conception journey—from planning and trying to conceive to pregnancy—helping you feel confident and cared for throughout.",
    "heading": "Conception Support Made Simple",
    "headSpan": "With Suti",
    "content":[
      "Access virtual consultations with fertility experts for guidance anytime.",
      "Expert insights, interviews, and Q&A on fertility and pregnancy topics.",
      "Connect with women, share stories, and find support on Suti."
    ]
  }
  const data4 = {
    "text": "",
    "para": "",
    "heading": "Why choose",
    "headSpan": "Suti?",
    "content":[
      "Actionable predictions empowering smarter business strategies.",
      "Confidential information safeguarded exclusively for you.",
      "Trusted knowledge for everyone."
    ]
  }

  return (
    <div className='image-text-component-section section-padding'>
      <Yoga />
      {/* <Accordian contentData={data1} />
      <LeftImageComponent contentData={data3} /> */}
      <BulletData contentData={data2} />
      <Diet/>
      {/* <LeftImageComponent contentData={data4} /> */}
      {/* <Integration /> */}
      <img src={wave} alt="component-top-wave" className='component-top-wave'></img>
      <img src={wave} alt="component-bottom-wave" className='component-bottom-wave'></img>
    </div>
  )
}

export default ImageTextComponent
