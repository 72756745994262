import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import bubble from '../../images/bubble2.webp'
import lines from '../../images/lines.webp'
import CommonHeading from './CommonHeading'
import img1 from '../../images/2618-removebg-preview.png'

const Diet = () => {
    const contentData = {
        "text": "",
        "para": "",
        "heading": "Personalized Pregnancy Diet Services on",
        "headSpan": "Suti",
        "content": []
    }
    return (
        <div className='diet-section left-image-component-section section-content'>
            <Container>
                <Row>
                    <Col lg={6} className='d-flex align-items-center'>
                        <div className='mb-30 mb-lg-0'>
                            <img src={img1} alt="left-img"></img>
                        </div>
                    </Col>
                    <Col lg={6} className='d-flex align-items-center'>
                        <div className='left-info'>
                            <CommonHeading text={contentData !== undefined && contentData.text} para={contentData !== undefined && contentData.para} head={contentData !== undefined && contentData.heading} headSpan={contentData !== undefined && contentData.headSpan} />
                            <p>The Suti App is your trusted companion for a healthy pregnancy journey, offering expert diet services tailored for pregnant women. Proper nutrition is vital during pregnancy, not only for the baby’s development but also for the mother’s well-being.</p>
                            <p>The app provides personalized diet plans designed by experts, ensuring you get the right balance of essential nutrients like folic acid, iron, calcium, and protein. Along with tracking your nutritional intake, Suti emphasizes the importance of a balanced diet to support healthy weight gain, boost energy levels, and reduce pregnancy-related discomforts. </p>
                            <p>Stay healthy and confident with Suti's dedicated diet services!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Diet
