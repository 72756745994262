import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import appStore from '../../images/appstore.webp'
import playStore from '../../images/googleplay.webp'
import wave from '../../images/banner_wave.webp'
import logo from '../../images/logo.png'
import eng from '../../images/englang.webp'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <Container>
        <div className='text-center first-section'>
          <h2 class="mb-10">We are here for your fertility <span>journey!</span> </h2>
          {/* <p>Download from App Store or Google Play.</p> */}
          <div className='d-flex align-items-center justify-content-center mt-50 download-app-img'>
            <NavLink>
              <img src={appStore} alt="app-store" className='app-store'></img>
            </NavLink>
            <NavLink>
              <img src={playStore} alt="play-store"></img>
            </NavLink>
          </div>
        </div>
        <div className='middle-section mt-80'>
          <Row className='align-items-center'>
            <Col lg={2}>
              <div className='logo'>
                <img src={logo} alt="logo"></img>
              </div>
            </Col>
            <Col lg={8}>
              <ul className='footer-links'>
                <li><NavLink>Home</NavLink></li>
                <li><NavLink>About</NavLink></li>
                <li><NavLink>Health Library</NavLink></li>
                <li><NavLink>Contact</NavLink></li>
              </ul>
            </Col>
            <Col lg={2}>
              <div className='dropdown'>
                <button id="dropdownMenuButton1" className='rounded-circle border-0 p-0 dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src={eng} alt="eng-flag" className='me-2'></img>
                  <small>English</small>
                </button>
                <ul className='dropdown-menu' aria-labelledby="dropdownMenuButton1">
                  <li><NavLink className="dropdown-item">English</NavLink></li>
                  <li><NavLink className="dropdown-item">Telugu</NavLink></li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className='last-section text-center'>
        <small class="small">Copyright © 2024 <a href="#" class="fw-bold text-decoration-underline">Irax</a>. All rights reserved.</small>
        </div>
      </Container>
      <img src={wave} alt="wave" className='wave-img'></img>
    </div>
  )
}

export default Footer
